.decree-deck {
    position: relative;
}

.decree-deck .card {
    font-size: 0.6vmin;
}

.decree-deck .known-decree {
    float: left;
    position: absolute;
    top: 0.5vmin;
    left: 0.5vmin;
}

.decree-deck .known-decree.active .card {
    box-shadow: 0 0 0.6vmin 0.4vmin hsl(190, 90%, 50%);
    cursor: pointer;
}
