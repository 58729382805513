#layout {
    text-align: center;
    display: grid;
    grid-template-rows: 150px 1fr;
}

#layout > header {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#layout > header h1 {
    font-size: 6em;
    font-family: 'Metamorphous';
    justify-content: center;
    margin: 0;
}

#layout > header h1 {
    font-size: 6em;
    font-family: 'Metamorphous';
    justify-content: center;
}

#layout > header nav ul {
    list-style: none;
}

#layout > header nav ul li {
    display: inline-block;
}

#layout > header nav ul li+li::before {
    content: '⋅';
    margin: 0 10px;
}

#layout > section {
    display: grid;
    align-items: center;
}

#layout > section > p,
#layout > section > * > p {
    margin-bottom: 0.8em;
}

#layout .content h1,
#layout .content h2,
#layout .content h3 {
    margin-bottom: 0.4em;
    margin-top: 1em;
}

#layout .content h1 {
    font-size: 2em;
}

#layout .content h2 {
    font-size: 1.6em;
}

#layout .content h3 {
    font-size: 1.2em;
}

#layout .content form {
    margin-top: 1em;
}
