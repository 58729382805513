.stack {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    height: 100%;
    padding: 4vmin;
    pointer-events: none;
    position: absolute;
    width: 100%;
    z-index: 50;
}

.stack ul {
    list-style: none;
}

.stack ul li {
    display: inline-block;
    pointer-events: all;
}

.stack .items li:not(:first-child) {
    margin-left: -4vmin;
}

.stack .requiringTargets li {
    float: right;
}

.stack .requiringTargets li:not(:first-child) {
    margin-right: -4vmin;
}

.stack .requiringTargets li:first-child .card {
    box-shadow: 0 0 0.6vmin 0.4vmin hsl(10, 90%, 50%);
}

.stack .card {
    font-size: 1vmin;
    transition: 120ms all ease-in-out;
}

.stack .card:hover {
    box-shadow: 0 0 0.4vmin 0.2vmin hsl(50, 90%, 50%);
}

.stack .add-to-stack .card {
    animation: enter-stack-you 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0s 1;
}

.stack .add-to-stack.opponent .card {
    animation: enter-stack-opponent 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0s 1;
}

.stack .resolve-item .card {
    animation: resolve-item 0.5s cubic-bezier(0.165, 0.840, 0.440, 1.000) 0s 1;
}

@keyframes enter-stack-you {
    from {
        transform: translateY(50vh) translateX(50vw);
    }
    to {
        transform: translateY(0) translateX(0);
    }
}

@keyframes enter-stack-opponent {
    from {
        transform: translateY(-50vh) translateX(50vw);
    }
    to {
        transform: translateY(0) translateX(0);
    }
}

@keyframes resolve-item {
    from {
        transform: scale(1);
        filter: blur(0px);
        opacity: 1;
    }
    to {
        transform: scale(2);
        filter: blur(4px);
        opacity: 0;
    }
}
