.card-on-board {
    margin: 0 0.5vmin;
}
.card-on-board .card {
    transition: 220ms all ease-in-out;
}

.card-on-board.activable :not(.zoom) > .card {
    box-shadow: 0 0 0.6vmin 0.4vmin hsl(190, 90%, 50%);
    cursor: pointer;
}

.card-on-board.conflictable :not(.zoom) > .card {
    box-shadow: 0 0 0.6vmin 0.4vmin hsl(10, 90%, 50%);
    cursor: pointer;
}

.card-on-board.targetable :not(.zoom) > .card {
    box-shadow: 0 0 0.6vmin 0.4vmin hsl(190, 90%, 50%);
    cursor: pointer;
}

.card-on-board.targetable :not(.zoom) > .card:hover {
    box-shadow: 0 0 0.6vmin 0.4vmin hsl(50, 90%, 50%);
}

.card-on-board.targeted :not(.zoom) > .card::before {
    color: red;
    content: '↓';
    font-size: 4vmin;
    position: absolute;
    text-align: center;
    top: -1em;
    width: 100%;
}

.card-on-board.opponent.targeted :not(.zoom) > .card::before {
    content: '↑';
    top: auto;
    bottom: -1em;
}

.card-on-board.exhausted :not(.zoom) > .card > * {
    opacity: 0.7;
}

.card-on-board .attachments {
    position: relative;
}

.card-on-board .attachments .card-with-zoom {
    position: absolute;
    left: -1vmin;
    top: -1vmin;
}

.card-on-board + .card-on-board.has-attachments {
    margin-left: 1.5vmin;
}
