/* energy symbols */
.energy {
    display: inline-block;
    text-align: center;

    line-height: 200%;
    height: 100%;
    width: 100%;
}

.energy span {
    background-image: url('/img/energy.png');
    background-size: 100% 100%;
    color: hsla(0, 0%, 0%, 0.9);
    display: inline-block;
    font-weight: bold;
    height: 100%;
    text-shadow: 0 0 1px white;
    width: 100%;
}

.card-text .energy {
    height: 1.2em;
    width: 1.1em;
    line-height: 1.2em;
    padding: 0;
}

.card-text .energy span {
    font-size: 80%;
}
