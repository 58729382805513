@font-face {
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 100;
    src: local('Public Sans Thin'),
         url('/fonts/public-sans/PublicSans-Thin.woff2') format('woff2'),
         url('/fonts/public-sans/PublicSans-Thin.woff') format('woff'),
         url('/fonts/public-sans/PublicSans-Thin.ttf') format('ttf');
}

@font-face {
    font-family: 'Public Sans';
    font-style: italic;
    font-weight: 100;
    src: local('Public Sans Thin Italic'),
         url('/fonts/public-sans/PublicSans-ThinItalic.woff2') format('woff2'),
         url('/fonts/public-sans/PublicSans-ThinItalic.woff') format('woff'),
         url('/fonts/public-sans/PublicSans-ThinItalic.ttf') format('ttf');
}

@font-face {
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 200;
    src: local('Public Sans Extra Light'),
         url('/fonts/public-sans/PublicSans-ExtraLight.woff2') format('woff2'),
         url('/fonts/public-sans/PublicSans-ExtraLight.woff') format('woff'),
         url('/fonts/public-sans/PublicSans-ExtraLight.ttf') format('ttf');
}

@font-face {
    font-family: 'Public Sans';
    font-style: italic;
    font-weight: 200;
    src: local('Public Sans Extra Light Italic'),
         url('/fonts/public-sans/PublicSans-ExtraLightItalic.woff2') format('woff2'),
         url('/fonts/public-sans/PublicSans-ExtraLightItalic.woff') format('woff'),
         url('/fonts/public-sans/PublicSans-ExtraLightItalic.ttf') format('ttf');
}

@font-face {
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 300;
    src: local('Public Sans Light'),
         url('/fonts/public-sans/PublicSans-Light.woff2') format('woff2'),
         url('/fonts/public-sans/PublicSans-Light.woff') format('woff'),
         url('/fonts/public-sans/PublicSans-Light.ttf') format('ttf');
}

@font-face {
    font-family: 'Public Sans';
    font-style: italic;
    font-weight: 300;
    src: local('Public Sans Light Italic'),
         url('/fonts/public-sans/PublicSans-LightItalic.woff2') format('woff2'),
         url('/fonts/public-sans/PublicSans-LightItalic.woff') format('woff'),
         url('/fonts/public-sans/PublicSans-LightItalic.ttf') format('ttf');
}

@font-face {
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Public Sans Regular'),
         url('/fonts/public-sans/PublicSans-Regular.woff2') format('woff2'),
         url('/fonts/public-sans/PublicSans-Regular.woff') format('woff'),
         url('/fonts/public-sans/PublicSans-Regular.ttf') format('ttf');
}

@font-face {
    font-family: 'Public Sans';
    font-style: italic;
    font-weight: 400;
    src: local('Public Sans Italic'),
         url('/fonts/public-sans/PublicSans-Italic.woff2') format('woff2'),
         url('/fonts/public-sans/PublicSans-Italic.woff') format('woff'),
         url('/fonts/public-sans/PublicSans-Italic.ttf') format('ttf');
}

@font-face {
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 500;
    src: local('Public Sans Medium'),
         url('/fonts/public-sans/PublicSans-Medium.woff2') format('woff2'),
         url('/fonts/public-sans/PublicSans-Medium.woff') format('woff'),
         url('/fonts/public-sans/PublicSans-Medium.ttf') format('ttf');
}

@font-face {
    font-family: 'Public Sans';
    font-style: italic;
    font-weight: 500;
    src: local('Public Sans Medium Italic'),
         url('/fonts/public-sans/PublicSans-MediumItalic.woff2') format('woff2'),
         url('/fonts/public-sans/PublicSans-MediumItalic.woff') format('woff'),
         url('/fonts/public-sans/PublicSans-MediumItalic.ttf') format('ttf');
}

@font-face {
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 600;
    src: local('Public Sans SemiBold'),
         url('/fonts/public-sans/PublicSans-SemiBold.woff2') format('woff2'),
         url('/fonts/public-sans/PublicSans-SemiBold.woff') format('woff'),
         url('/fonts/public-sans/PublicSans-SemiBold.ttf') format('ttf');
}

@font-face {
    font-family: 'Public Sans';
    font-style: italic;
    font-weight: 600;
    src: local('Public Sans SemiBold Italic'),
         url('/fonts/public-sans/PublicSans-SemiBoldItalic.woff2') format('woff2'),
         url('/fonts/public-sans/PublicSans-SemiBoldItalic.woff') format('woff'),
         url('/fonts/public-sans/PublicSans-SemiBoldItalic.ttf') format('ttf');
}

@font-face {
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 700;
    src: local('Public Sans Bold'),
         url('/fonts/public-sans/PublicSans-Bold.woff2') format('woff2'),
         url('/fonts/public-sans/PublicSans-Bold.woff') format('woff'),
         url('/fonts/public-sans/PublicSans-Bold.ttf') format('ttf');
}

@font-face {
    font-family: 'Public Sans';
    font-style: italic;
    font-weight: 700;
    src: local('Public Sans Bold Italic'),
         url('/fonts/public-sans/PublicSans-BoldItalic.woff2') format('woff2'),
         url('/fonts/public-sans/PublicSans-BoldItalic.woff') format('woff'),
         url('/fonts/public-sans/PublicSans-BoldItalic.ttf') format('ttf');
}

@font-face {
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 800;
    src: local('Public Sans Extra Bold'),
         url('/fonts/public-sans/PublicSans-ExtraBold.woff2') format('woff2'),
         url('/fonts/public-sans/PublicSans-ExtraBold.woff') format('woff'),
         url('/fonts/public-sans/PublicSans-ExtraBold.ttf') format('ttf');
}

@font-face {
    font-family: 'Public Sans';
    font-style: italic;
    font-weight: 800;
    src: local('Public Sans Extra Bold Italic'),
         url('/fonts/public-sans/PublicSans-ExtraBoldItalic.woff2') format('woff2'),
         url('/fonts/public-sans/PublicSans-ExtraBoldItalic.woff') format('woff'),
         url('/fonts/public-sans/PublicSans-ExtraBoldItalic.ttf') format('ttf');
}

@font-face {
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 900;
    src: local('Public Sans Black'),
         url('/fonts/public-sans/PublicSans-Black.woff2') format('woff2'),
         url('/fonts/public-sans/PublicSans-Black.woff') format('woff'),
         url('/fonts/public-sans/PublicSans-Black.ttf') format('ttf');
}

@font-face {
    font-family: 'Public Sans';
    font-style: italic;
    font-weight: 900;
    src: local('Public Sans Black Italic'),
         url('/fonts/public-sans/PublicSans-BlackItalic.woff2') format('woff2'),
         url('/fonts/public-sans/PublicSans-BlackItalic.woff') format('woff'),
         url('/fonts/public-sans/PublicSans-BlackItalic.ttf') format('ttf');
}
