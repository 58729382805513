.library {
    display: grid;
}

.library p {
    color: white;
    display: none;
    font-size: 5em;
    margin: auto;
    text-shadow: 0 0 1px black;
}

.library:hover p {
    display: block;
}
