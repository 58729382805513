.controls {
    display: grid;
    grid-template-rows: auto;
    padding: 1vmin 1vmin;
    text-align: center;
}

.controls img {
    width: 2vmin;
}

.controls .special-action > * + * {
    margin-left: 0.7vmin;
}
