.player-influence {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.player-influence .influence {
    align-items: center;
    border: 0.5vmin solid black;
    border-radius: 50%;
    display: inline-flex;
    font-size: 2vmin;
    justify-content: center;
    min-width: 5vmin;
    vertical-align: middle;
}

.player-influence .influence:before {
    content: '';
    float: left;
    width: auto;
    padding-bottom: 100%;
}

.player-influence .desc {
    color: hsl(0, 0%, 30%);
    font-size: 0.6em;
    text-transform: uppercase;
}
