.card-with-zoom {
    position: relative;
}

/* Card size */
.card-with-zoom.small > .card {
    font-size: 0.6vmin;
}

.card-with-zoom.medium > .card {
    font-size: 0.8vmin;
}

.card-with-zoom.normal > .card {
    font-size: 1vmin;
}

.card-with-zoom .zoom {
    font-size: 2vmin;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    z-index: 100;
}

.card-with-zoom .zoom .card {
    font-size: 2vmin;
}

/* Zoom position */
.card-with-zoom.small .zoom.center {
    left: calc(-14em / 2 + 14em / 2 * 0.3);
}

.card-with-zoom.medium .zoom.center {
    left: calc(-14em / 2 + 14em / 2 * 0.4);
}

.card-with-zoom.normal .zoom.center {
    left: calc(-14em / 2 + 14em / 2 * 0.5);
}

.card-with-zoom .zoom.left {
    left: -14.5em;
}

.card-with-zoom.small .zoom.right {
    left: calc(0.3 * 14em + 0.5em);
}

.card-with-zoom.medium .zoom.right {
    left: calc(0.4 * 14em + 0.5em);
}

.card-with-zoom.normal .zoom.right {
    left: calc(0.5 * 14em + 0.5em);
}

.card-with-zoom .zoom.bottom {
    bottom: -1em;
}

.card-with-zoom .zoom.top {
    top: -1em;
}

.card-with-zoom.small .zoom.middle {
    top: calc(-0.5 * 21em + 0.5 * 21em * 0.5 * 0.6);
}

.card-with-zoom.medium .zoom.middle {
    top: calc(-0.5 * 21em + 0.5 * 21em * 0.5 * 0.8);
}

.card-with-zoom.normal .zoom.middle {
    top: calc(-0.5 * 21em + 0.5 * 21em * 0.5);
}

/* Zoom showing */

/* Default effect */
.card-with-zoom:hover .zoom {
    opacity: 1;
}

/* ----------------------------------------------
* Generated by Animista on 2020-9-16 11:9:36
* Licensed under FreeBSD License.
* See http://animista.net/license for more info.
* w: http://animista.net, t: @cssanimista
* ---------------------------------------------- */

/**
* ----------------------------------------
* animation slit-in-vertical
* ----------------------------------------
*/
@-webkit-keyframes slit-in-vertical {
    0% {
        -webkit-transform: translateZ(-800px) rotateY(90deg);
        transform: translateZ(-800px) rotateY(90deg);
        opacity: 0;
    }
    30% {
        -webkit-transform: translateZ(-800px) rotateY(90deg);
        transform: translateZ(-800px) rotateY(90deg);
        opacity: 0;
    }
    64% {
        -webkit-transform: translateZ(-160px) rotateY(87deg);
        transform: translateZ(-160px) rotateY(87deg);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateZ(0) rotateY(0);
        transform: translateZ(0) rotateY(0);
    }
}
@keyframes slit-in-vertical {
    0% {
        -webkit-transform: translateZ(-800px) rotateY(90deg);
        transform: translateZ(-800px) rotateY(90deg);
        opacity: 0;
    }
    30% {
        -webkit-transform: translateZ(-800px) rotateY(90deg);
        transform: translateZ(-800px) rotateY(90deg);
        opacity: 0;
    }
    64% {
        -webkit-transform: translateZ(-160px) rotateY(87deg);
        transform: translateZ(-160px) rotateY(87deg);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateZ(0) rotateY(0);
        transform: translateZ(0) rotateY(0);
    }
}

.card-with-zoom.slit-in:hover .zoom {
    -webkit-animation: slit-in-vertical 0.3s ease-out 0s forwards;
    animation: slit-in-vertical 0.3s ease-out 0s forwards;
}
