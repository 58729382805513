.card-stats {
    background-color: hsla(0, 0%, 0%, 0.5);
    border-radius: 0 0 0.8em 0.8em;
    display: flex;
    justify-content: space-around;
}

.card-stats p {
    font-size: 150%;
    font-weight: bold;
    line-height: 150%;
    display: flex;
}

.card-stats img {
    margin-right: 0.2em;
    width: 1em;
}

.card-stats .actions img {
    padding-top: 0.1em;
    width: 0.8em;
}
