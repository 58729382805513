.void {
    box-shadow: 0 0 0.6vmin #ccc;
    border-radius: 0.6vmin;
    cursor: pointer;
    font-size: 0.6vmin;
    height: 21em;
    position: relative;
    width: 14em;
}

.void .card {
    float: left;
    font-size: 0.6vmin;
}

.void > p {
    background-color: rgba(0, 0, 0, 0.5);
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 0.6vmin;
    color: white;
    display: none;
    font-size: 5em;
    height: 100%;
    text-shadow: 0 0 1px black;
    position: absolute;
    width: 100%;
}


.void:hover > p {
    display: grid;
}

.void:hover > p span {
    margin: auto;
}
