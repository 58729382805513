.error-screen {
}

.error-screen h2 {
    font-size: 2em;
    margin-bottom: 2vh;
}

.error-screen h3 {
    font-size: 1.4em;
    margin-bottom: 3vh;
}

.error-screen .error {
    background-color: hsl(0, 0%, 80%);
    border-radius: 1vmin;
    font-family: Courrier;
    margin: auto;
    padding: 2vmin 1vmin;
    width: 60%;
}
