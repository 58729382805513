@keyframes heartbeat {
    from {
        font-size: 0.6vmin;
    }
    70% {
        font-size: 0.8vmin;
    }
    to {
        font-size: 0.8vmin;
    }
}

.boost-card.highlighted :not(.zoom) > .card {
    animation: heartbeat 0.3s ease-out 0s 2 alternate forwards;
}
