@keyframes zoomOut {
    from {
        font-size: 0.6vmin;
    }
    10% {
        font-size: 0.8vmin;
    }
    to {
        font-size: 0vmin;
    }
}

@keyframes zoomIn {
    from {
        font-size: 0.6vmin;
    }
    10% {
        font-size: 0.4vmin;
    }
    to {
        font-size: 2vmin;
    }
}

@keyframes textZoomIn {
    from {
        font-size: 0em;
    }
    20% {
        font-size: 4em;
    }
    to {
        font-size: 4.2em;
    }
}

.conflict-summary {
    background-color: hsl(14, 100%, 90%);
    display: grid;
    position: relative;
    grid-template-columns: 80% 20%;
    grid-template-rows: 50% 50%;
}

.conflict-summary .supporters {
    align-items: center;
    display: flex;
    grid-column: 1 / 2;
    justify-content: flex-end;
    list-style: none;
}

.conflict-summary .supporters li {
    display: inline-block;
    margin: 0 0.2em;
}

.conflict-summary .objective {
    display: flex;
    align-items: center;
    justify-content: space-around;
    grid-column: 2 / span 1;
    grid-row: 1 / span 2;
}

.conflict-summary .objective > p {
    font-weight: bold;
    font-size: 2em;
    color: hsl(0, 90%, 25%);
}

.conflict-summary .objective > p.uncertain {
    color: hsl(30, 90%, 35%);
}

.conflict-summary .objective > p.success {
    color: hsl(100, 90%, 25%);
}

.conflict-summary .result {
    align-items: center;
    background-color: hsla(14, 100%, 20%, 0.8);
    display: flex;
    justify-content: center;
    position: absolute;
    height: 100%;
    width: 100%;
}

.conflict-summary .result p {
    animation: textZoomIn 2s ease-out 0s 1 normal forwards;
    color: hsl(0, 100%, 100%);
    font-family: 'Metamorphous', serif;
    font-size: 4em;
    text-shadow: 0 0 2px hsl(0, 100%, 0%);;
    text-transform: uppercase;
}

.conflict-summary .decree.abandoned .zoom,
.conflict-summary .decree.won .zoom,
.conflict-summary .decree.lost .zoom {
    display: none;
}

.conflict-summary .decree.won .card {
    animation: zoomIn 1s ease-out 1s 1 normal forwards;
}

.conflict-summary .decree.abandoned .card,
.conflict-summary .decree.lost .card {
    animation: zoomOut 1s ease-out 1s 1 normal forwards;
}
