.rules-guide {
    font-family: serif;
    text-align: left;
    line-height: 1.4;
    margin: auto;
    max-width: 35em;
}

#layout section.rules-guide {
    display: block;
}

.rules-guide img {
    box-shadow: 0 0 1em black;
    display: block;
    margin: 2em auto;
    max-width: 100%;
}

.rules-guide ol,
.rules-guide ul {
    margin-bottom: 0.8em;
    margin-left: 2em;
}

.rules-guide img.side {
    float: left;
    margin: 0 1em 0.4em 0;
    width: 200px;
}

.rules-guide img.side:nth-of-type(odd) {
    float: right;
    margin: 0 0 0.4em 1em;
}

.rules-guide .clear {
    clear: both;
}
