.card-back {
    background-color: black;
    background-image: url(/img/backgrounds/card_back.svg);
    background-size: 100% 100%;
    border-radius: 0.6vmin;
    display: grid;
    font-size: 0.6vmin;
    height: 21em;
    width: 14em;
}
