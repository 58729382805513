.player-avatar {
    height: 9vmin;
    margin: auto;
    text-align: center;
    width: 8vmin;
}

.player-avatar img {
    border: 0.5vmin solid black;
    border-radius: 100%;
    box-sizing: border-box;
    height: 8vmin;
    width: 8vmin;
}

.player-avatar.active img {
    border-color: hsl(44, 61%, 51%);
    box-shadow: 0 0 0.8em 0.4em hsl(44, 61%, 51%);
}

.player-avatar.targetable img {
    box-shadow: 0 0 0.6vmin 0.4vmin hsl(190, 90%, 50%);
    cursor: pointer;
}

.player-avatar .targetable img:hover {
    box-shadow: 0 0 0.6vmin 0.4vmin hsl(10, 90%, 50%);
}
