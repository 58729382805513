.card-text {
    background-color: hsla(0, 0%, 0%, 0.7);
    border: 0.1em solid black;
    border-left: none;
    border-right: none;
    font-family: 'Public Sans', sans-serif;
    font-size: 80%;
    line-height: 1.2;
    text-align: left;
    overflow: auto;
}

.card-text p {
    margin: 0.2em 0;
}

.card-text.big {
    font-size: 90%;
}

.card-text.medium {
    font-size: 80%;
}

.card-text.small {
    font-size: 70%;
    line-height: 1.3;
}

.card-text .reminder {
    font-style: italic;
    line-height: 1;
}

.card-text img {
    vertical-align: baseline;
    width: 0.8em;
}
