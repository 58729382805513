.mulligan .card {
    font-size: 2vmin;
}

.mulligan .content li {
    display: block;
    margin: 0 1vmin;
}

@keyframes slidein {
    from {
        left: 100vw;
    }
    to {
        left: 0;
    }
}

@keyframes slideout {
    from {
        left: 0;
    }
    to {
        left: -100vw;
    }
}

.mulligan .content li .card {
    position: relative;
}

.mulligan .content li.selected .card {
    box-shadow: 0 0 4vmin orange;
}

.mulligan .content li.discarded .card {
    animation: slideout 0.5s ease-out 0s 1 normal forwards;
}

.mulligan .content li.drawn .card {
    animation: slidein 0.5s ease-out 0s 1 normal forwards;
}
