@keyframes fadeIn {
    from {
        background-color: hsla(0, 0%, 0%, 0);
    }
    10% {
        background-color: hsla(0, 0%, 0%, 0.3);
    }
    90% {
        background-color: hsla(0, 0%, 0%, 0.3);
    }
    to {
        background-color: hsla(0, 0%, 0%, 0);
    }
}

@keyframes appearInOut {
    from {
        font-size: 0;
    }
    40% {
        font-size: 9vmin;
    }
    90% {
        font-size: 10vmin;
    }
    to {
        font-size: 0;
    }
}

.player-turn-animation {
    background-color: hsla(0, 0%, 0%, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    position: absolute;
    width: 100vw;
    z-index: 100;
}

.player-turn-animation p {
    font-family: Metamorphous, serif;
    font-size: 10vmin;
    color: hsl(45, 90%, 80%);
    text-shadow: 0 0 1vmin hsl(45, 90%, 20%);
}

.player-turn-animation.animate {
    animation: fadeIn 1s linear 0s 1 normal forwards;
}

.player-turn-animation.animate p {
    animation: appearInOut 1s linear 0s 1 normal forwards;
}
