html,
body,
#root,
.bgio-client,
main {
    background-color: hsl(0, 0%, 90%);
    height: 100%;
    width: 100%;
}

.bgio-client {
    overflow: hidden;
}
