.souls-board {
    display: grid;
    grid-template-rows: 4vh 12vh 1fr 0vh 1fr 12vh 8vh;
    height: 100%;
    transition: 220ms all ease-in-out;
}

.souls-board.conflict {
    grid-template-rows: 4vh 12vh 1fr 30vh 1fr 12vh 8vh;
}

.souls-board .extra {
    display: grid;
    justify-content: space-around;
    grid-template-columns: 1fr 1fr 1fr;
}

.souls-board .player-zones {
    display: flex;
    margin: auto;
}

.souls-board .player-zones div:first-child {
    margin-right: 1vmin;
}

.souls-board .player-name {
    position: absolute;
    font-weight: bold;
    font-family: 'Metamorphous';
    margin: 2vmin;
    left: 0;
    bottom: 0;
}

.souls-board .player-name.opponent {
    bottom: auto;
    top: 0;
}

.souls-board .debug-data {
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 2vmin;
    font-size: 0.8em;
    color: hsl(0, 0%, 50%);
}
