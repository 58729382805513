.player-energy {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.player-energy .value {
    border: 0.5vmin solid black;
    border-radius: 100vmin;
    display: inline;
    font-size: 2vmin;
    padding: 1vmin 2vmin;
}

.player-energy .desc {
    color: hsl(0, 0%, 30%);
    font-size: 0.6em;
    text-transform: uppercase;
}
