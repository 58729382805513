.card-miniature .image {
    height: 75%;
}

.card-miniature .image img {
    object-fit: cover;
}

.card-miniature .card-stats {
    height: 18%;
}

.card-miniature .card-stats p {
    font-size: 250%;
}
