.gameover {
    background-color: hsla(0, 0%, 0%, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    position: absolute;
    width: 100vw;
    z-index: 100;
}

.gameover h1 {
    color: hsla(360, 100%, 100%, 0.9);
    font-family: 'Metamorphous';
    font-size: 4em;
}

.gameover p {
    margin-top: 4em;
}

.gameover p span {
    color: hsl(0, 0%, 90%);
}
