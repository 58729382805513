.player-board {
    display: grid;
    grid-template-columns: 1fr auto;
}

.player-board > * {
    align-items: center;
    display: flex;
    justify-content: center;
    list-style: none;
}

.player-board ul li {
    position: relative;
}

.player-board ul li .card:not(:last-child) {
    position: absolute;
    left: 1vmin;
    top: -1vmin;
}
