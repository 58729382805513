.cards-list {
    padding: 0 1em;
}

.cards-list h2 {
    font-size: 1.4em;
    margin: 1em 0 0.4em 0;
}

.cards-list ul {
    list-style: none;
}

.cards-list ul li {
    display: inline-block;
    margin: 0 0.4em  0.4em 0;
}
