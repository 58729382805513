.modal {
    background-color: hsla(0, 0%, 0%, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    position: absolute;
    width: 100vw;
    z-index: 100;
}

.modal header {
    color: hsl(360, 0%, 90%);
    margin-bottom: 5vh;
    text-align: center;
}

.modal header h1 {
    font-size: 4vmin;
    margin-bottom: 1vh;
}

.modal .content {
    display: flex;
    justify-content: center;
    font-size: 2vmin;
    height: 21em;
    width: 100%;
}

.modal .options {
    text-align: center;
    margin-top: 5vh;
}

.modal .options > * + * {
    margin-left: 0.7vmin;
}
