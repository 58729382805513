:root {
    --button-color-h: 50;
    --button-color-s: 100%;
    --button-color-l: 45%;

    --button-secondary-color-h: 195;
    --button-secondary-color-s: 30%;
    --button-secondary-color-l: 65%;
}

button.button {
    border: none;
    font-family: inherit;
    cursor: pointer;

    font-size: 1.2rem;
    font-weight: bold;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    align-self: start;

    background-color: hsl(
        var(--button-color-h),
        var(--button-color-s),
        var(--button-color-l)
    );
    /* color: #fff; */
    border-radius: 100vmin;
    box-shadow: 0 3px 5px hsla(0, 0%, 0%, 0.55);

    padding: 0.25em 1em;
    min-width: 9ch;
    min-height: 44px;

    text-align: center;
    line-height: 1.1;

    transition: 220ms all ease-in-out;
}

button.button:hover,
button.button:active {
    background-color: hsl(
        var(--button-color-h),
        var(--button-color-s),
        calc(var(--button-color-l) + 20%)
    );
}

/* Commented out because it's ugly but ideally that would be better for accessibility. */
/* button.button:focus {
    outline-style: solid;
    outline-color: transparent;
    box-shadow: 0 0 0 4px hsl(
        var(--button-color-h),
        var(--button-color-s),
        calc(var(--button-color-l) - 30%)
    );
} */

button.button.secondary {
    background-color: hsl(
        var(--button-secondary-color-h),
        var(--button-secondary-color-s),
        var(--button-secondary-color-l)
    );
}

button.button.secondary:hover,
button.button.secondary:active {
    background-color: hsl(
        var(--button-secondary-color-h),
        var(--button-secondary-color-s),
        calc(var(--button-secondary-color-l) + 20%)
    );
}

button.button[disabled] {
    color: hsl(0, 0%, 90%);
    background-color: hsl(0, 0%, 60%);
    cursor: wait;
}

@media screen and (-ms-high-contrast: active) {
    button.button {
        border: 2px solid currentcolor;
    }
}
