.hand {
    display: flex;
    justify-content: center;
    list-style: none;
    vertical-align: bottom;
    height: 100%;
}

.hand li {
    margin-left: -2vmin;
    position: relative;
}

/* Override behavior of the CardWithZoom component to make bottom be the
 * actual bottom of the screen.
 */
.hand li .card-with-zoom {
    position: static;
}

.hand li .card-with-zoom .zoom {
    bottom: 0;
}

.hand li .card {
    font-size: 1vmin;
}

.hand li.playable .card {
    cursor: pointer;
    box-shadow: 0 0 0.6vmin 0.4vmin hsl(190, 90%, 50%);
}

.hand li.conflictable .card {
    cursor: pointer;
    box-shadow: 0 0 0.6vmin 0.4vmin hsl(10, 90%, 50%);
}

.hand.opponent li {
    top: -8vmin;
}

.hand.opponent .card-back {
    border-radius: 0.6vmin;
    font-size: 0.6vmin;
}
