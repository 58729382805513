/* Sizes */
/*
 * Everything is computed based on the font-size of the root element, so to resize
 * cards from the outside, one just needs to adapt the font-size of .card.
 */
.card {
    font-size: 2vmin;
    height: 21em;
    width: 14em;
}
.card > div {
    height: 100%;
    margin: 0;
    width: 100%;
}

.card .title,
.card .type {
    height: 7%;
}
.card .image {
    height: 50%;
}
.card .card-text {
    height: 25%;
}
.card .card-stats {
    height: 11%;
}

.card .type,
.card .card-text,
.card .card-stats {
    padding: 0 0.3em;
}
.card .type-resource .image {
    height: 92.7%;
}
.card .image img {
    height: 100%;
    width: 100%;
}
.card .card-stats {
    width: 100%;
}

/* the rest */
.card {
    background-color: black;
    color: hsl(0, 0%, 100%);
    border: 0.1em solid black;
    font-family: 'Public Sans', serif;
    position: relative;
    text-shadow: 0 0 1px black;
}

.card,
.card > div {
    border-radius: 0.8em;
}

.card > div {
    background-image: url('/img/backgrounds/colorless.jpg');
}
.card.color-blue > div {
    background-image: url('/img/backgrounds/blue.jpg');
}
.card.color-green > div {
    background-image: url('/img/backgrounds/green.jpg');
}
.card.color-red > div {
    background-image: url('/img/backgrounds/red.jpg');
}
.card.color-purple > div {
    background-image: url('/img/backgrounds/purple.jpg');
}
.card.color-yellow > div {
    background-image: url('/img/backgrounds/yellow.jpg');
}

.card .title,
.card .type {
    background-color: hsla(0, 0%, 0%, 0.4);
    font-size: 80%;
    font-weight: bold;
    line-height: 180%;
}

.card .title,
.card .type {
    font-family: 'Metamorphous', serif;
}

.card .title {
    background: linear-gradient(
        90deg,
        rgba(0,0,0,0) 0%,
        rgba(0,0,0,0.4) 20%,
        rgba(0,0,0,0.4) 80%,
        rgba(0,0,0,0) 100%
    );
    border-radius: 0.8em 0.8em 0 0;
}

.card .requirements {
    width: 12%;
    position: absolute;
}
.card .requirements .cost {
    height: 100%;
    position: relative;
    text-shadow: 0 0 0.2em white;
    z-index: 20;
}
.card .requirements .energy span {
    background-image: url('/img/cost.png');
}
.card .requirements .levels {
    background-color: rgba(147, 147, 147, 0.7);
    border-color: black;
    border-style: solid;
    border-radius: 0 0 1em 1em;
    border-width: 0 0.1em 0.1em 0.1em;
    box-shadow: 0.1em 0.1em 2px black;
    box-sizing: border-box;
    display: inline-block;
    margin-left: 10%;
    list-style-type: none;
    padding: 0.1em;
    padding-top: 1em;
    position: relative;
    text-align: center;
    top: -1em;
    width: 80%;
    z-index: 10;
}

.card .type {
    background-color: hsla(0, 0%, 0%, 0.4);
    background: linear-gradient(
        90deg,
        rgba(0,0,0,0.4) 0%,
        rgba(0,0,0,0.4) 60%,
        rgba(0,0,0,0) 100%
    );
    text-align: left;
}

.card .image {
    background-color: white;
    border: 1px solid black;
    border-left: none;
    border-right: none;
}

.card .title .cost img {
    vertical-align: baseline;
    width: 0.8em;
}
.card .title h1 {
    text-align: center;
}
